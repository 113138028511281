import useAuth from '@/composables/useAuth';

export function useChilipiper() {
  const { currentOrg, currentUser } = useAuth();

  const displayChilipiperWidget = ({
    domElement,
    chilipiperRouter,
    campaign,
    onSuccess,
    onClose,
    onError = onClose,
  }: {
    domElement: string;
    chilipiperRouter: string;
    campaign: string;
    onSuccess: () => void;
    onClose: () => void;
    onError?: () => void;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ChiliPiper.submit('reveal', chilipiperRouter, {
      lead: {
        Campaign_content__c: campaign,
        Company: currentOrg.value.name,
        Email: currentUser.value.email,
        FirstName: currentUser.value.first_name,
        LastName: currentUser.value.last_name,
        LeadSource: 'Chili Piper',
        Time_Zone__c: new Date().getTimezoneOffset(),
      },
      map: true,
      domElement: `#${domElement}`,
      onSuccess,
      onClose,
      onError,
    });
  };

  return { displayChilipiperWidget };
}
